<template>
  <scroll-container>
    <container>
      <card-section>
        <card-list>
          <card-list-header class="info">
            <h5 class="mt-2 text-white">Invalid/expired authorization</h5>
            <p>The link provided has expired or is no longer valid.</p>
          </card-list-header>
        </card-list>
      </card-section>

      <card-section class="text-center">
        <btn-group>
          <btn class="btn round lg success" @click="() => $store.dispatch('to', '/pub/login')">
            <template #icon>
              <font-awesome-icon icon="arrow-right" />
            </template>
            Click here to login..
          </btn>
        </btn-group>
      </card-section>

      <card-section>
        <card-list>
          <card-list-header class="info">
            Trying to see your quote, proposal or change-order?
          </card-list-header>
          <card-list-item>
            <h5>Possible reasons for error:</h5>
            <div>
              <ul>
                <li>
                  You most likely need to delete cache and cookies (<a
                    class="text-info"
                    @click="goToInstructions"
                    >see instructions here</a
                  >), then refresh, then follow the link in your email again.
                </li>
                <li>
                  You may need a "hard-reload" to update your app version (<a
                    class="text-info"
                    @click="goToHardReloadInstructions"
                    >see instructions here</a
                  >).
                </li>
                <li class="text-muted">
                  The link provided to you may relate to a project proposal or change-order that no
                  longer exists.
                </li>
                <li class="text-muted">The link provided may be expired.</li>
                <li class="text-muted">You may have rejected the proposal previously.</li>
                <li class="text-muted">Your user may be inactive.</li>
              </ul>
            </div>

            <h5>How to fix:</h5>
            <div>
              <ul>
                <li>
                  Clear your browser cache and cookies (<a
                    class="text-info"
                    @click="goToInstructions"
                    >see instructions here</a
                  >), then refresh the page, then follow the link in your email again.
                </li>
                <li>
                  If that doesn't work, try a "hard-reload" (<a
                    class="text-info"
                    @click="goToHardReloadInstructions"
                    >see instructions here</a
                  >).
                </li>

                <li class="text-muted">
                  Contact the company/user that provided the link to have it re-sent to you.
                </li>
                <li class="text-muted">
                  If you know the email address and password associated with your account,
                  <router-link to="/">click here to login.</router-link>
                </li>
              </ul>
            </div>
          </card-list-item>
        </card-list>
      </card-section>
    </container>
  </scroll-container>
</template>

<script>
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  methods: {
    goToInstructions() {
      window.location.href = 'https://www.wikihow.com/Clear-Cache-and-Cookies'
    },
    goToHardReloadInstructions() {
      window.location.href =
        'https://www.getfilecloud.com/blog/2015/03/tech-tip-how-to-do-hard-refresh-in-browsers/'
    }
  },
  mounted() {
    _.clearStorage()
  },

  data() {
    return {
      startingTab: 'Leads'
    }
  }
}
</script>
